import { z } from "zod";

const programTierSchema = z.object({
  cohort: z.number(),
  intervalEarning: z.number(),
});

const programConfigSchema = z.object({
  tiers: z.array(programTierSchema),
  infoPage: z.string().optional(),
  cardTitle: z.string().optional(),
  cardDescription: z.string().optional(),
  testMode: z.boolean().default(false),
});

const programStateSchema = z.object({
  participants: z.number().optional(),
  totalRedeemed: z.number().optional(),
  activeParticipants: z.number().optional(),
});

const programStateGenesisSchema = z.object({
  participants: z.number().optional(),
  totalRedeemed: z.number().optional(),
});

const boostSchema = z.object({
  boostId: z.string(),
  rewardsProgramId: z.string(),
  projectedSpendUsd: z.number(),
  actualSpendUsd: z.number(),
  boost: z.object({
    id: z.string(),
    status: z.enum(["ACTIVE", "SCHEDULED", "COMPLETE", "DRAFT"]),
    boostType: z.enum(["USER", "CHAIN"]),
    boostTarget: z.string(),
    multiplier: z.number(),
    startsAt: z.coerce.date(),
    endsAt: z.coerce.date(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date(),
    createdBy: z.string(),
    updatedBy: z.string(),
  }),
});

export const schema = z.object({
  id: z.string(),
  name: z.string(),
  programConfig: programConfigSchema,
  programState: programStateSchema,
  programEpoch: z.number(),
  programStateGenesis: programStateGenesisSchema,
  updates: z.string(),
  programRefresh: z.string(),
  programType: z.string(),
  programDescription: z.string(),
  startsAt: z.coerce.date().optional(),
  endsAt: z.coerce.date().optional(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  nextRefresh: z.coerce.date(),
  lastRefresh: z.coerce.date(),
  boosts: z.array(boostSchema).optional(),
});

export type Program = z.infer<typeof schema>;
