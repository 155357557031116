import { z } from "zod";
import { schema as tagSchema } from "../tag";
import { bucketSchema, prerequisiteSchema, rewardTypeSchema } from "./reward-config";

const questUserStatusSchema = z.union([
  z.literal("STARTED"),
  z.literal("CLAIMED"),
  z.literal("VALIDATING"),
  z.literal("COMPLETED"),
  z.literal("FAILED"),
]);

// All step types must have a name
const stepSchema = z.object({ name: z.string() });

const infoStepSchema = stepSchema.extend({
  type: z.literal("info"),
  descriptionMarkdown: z.string(),
  mediaUrl: z.string().url(),
  mediaType: z.enum(["image", "video", "youtube"]),
  taskButtonLabel: z.string().optional().nullable(),
  taskButtonLink: z.string().optional().nullable(),
});

const questStepSchema = z.discriminatedUnion("type", [
  infoStepSchema,
  // other slide/step types
]);

// I dislike having to hard code these values, but it will have to do until a more dynamic solution is implemented
export const questCategoryTypes = z.enum(["Featured", "Getting Started", "Explore"]);

type QuestConfig = {
  name: string;
  displayName: string;
  visible: boolean;
  currency: string;
  abbreviation: string;
  rewardChain: string;
  scoreChain: string;
};

export const questConfigs = {
  near: {
    name: "near",
    displayName: "Near",
    visible: true,
    currency: "NEAR",
    abbreviation: "NEAR",
    rewardChain: "near",
    scoreChain: "near",
  },
  aptos: {
    name: "aptos",
    displayName: "Aptos",
    visible: true,
    currency: "APT",
    abbreviation: "APT",
    rewardChain: "aptos",
    scoreChain: "aptos",
  },
  avalanche: {
    name: "avalanche",
    displayName: "Avalanche",
    visible: true,
    currency: "AVAX",
    abbreviation: "AVAX",
    rewardChain: "avalanche",
    scoreChain: "avalanche",
  },
  flow: {
    name: "flow",
    displayName: "Flow",
    visible: true,
    currency: "FLOW",
    abbreviation: "FLOW",
    rewardChain: "flow",
    scoreChain: "flow",
  },
  solana: {
    name: "solana",
    displayName: "Solana",
    visible: true,
    currency: "SOL",
    abbreviation: "SOL",
    rewardChain: "solana",
    scoreChain: "solana",
  },
  axelar: {
    name: "axelar",
    displayName: "Axelar",
    visible: true,
    currency: "AXL",
    abbreviation: "AXL",
    rewardChain: "axelar",
    scoreChain: "axelar",
  },
  blast: {
    name: "blast",
    displayName: "Blast",
    visible: true,
    currency: "BLAST",
    abbreviation: "Blast",
    rewardChain: "blast",
    scoreChain: "blast",
  },
  kaia: {
    name: "kaia",
    displayName: "Kaia",
    visible: true,
    currency: "KAIA",
    abbreviation: "Kaia",
    rewardChain: "kaia",
    scoreChain: "kaia",
  },
  olas: {
    name: "olas",
    displayName: "Olas",
    visible: true,
    currency: "OLAS",
    abbreviation: "Olas",
    rewardChain: "gnosis",
    scoreChain: "ethereum",
  },
  sei: {
    name: "sei",
    displayName: "Sei",
    visible: true,
    currency: "SEI",
    abbreviation: "SEI",
    rewardChain: "sei",
    scoreChain: "sei",
  },
};

export const questConfigList = Object.values(questConfigs) as QuestConfig[];

export const questEcosystemSchema = z.enum(
  Object.keys(questConfigs) as [keyof typeof questConfigs, ...Array<keyof typeof questConfigs>],
);

export const schema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string(),
  status: z.enum(["draft", "published"]),
  numActionsRequired: z.number(),
  timeToCompleteMin: z.number(),
  startDate: z.coerce.date(),
  endDate: z.coerce.date(),
  slug: z.string(),
  campaignId: z.string(),
  goal: z.union([
    z.literal("connections"),
    z.literal("education"),
    z.literal("transactionNumber"),
    z.literal("transactionDollars"),
  ]),
  goalAmount: z.number(),
  steps: z.array(questStepSchema),
  tags: z.array(tagSchema),
  project: tagSchema,
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  publishedAt: z.coerce.date().optional().nullable(),
  createdById: z.string(),
  updatedById: z.string().optional().nullable(),
  link: z.string().url().optional().nullable(),
  linkText: z.string().optional().nullable(),
  category: questCategoryTypes,
  validationChain: z.string(),
  prerequisites: z.array(prerequisiteSchema),
});

export const questUpsertSchema = schema
  .omit({
    id: true,
    createdAt: true,
    updatedAt: true,
    publishedAt: true,
    tags: true,
    project: true,
    slug: true,
    startDate: true,
    endDate: true,
    updatedById: true,
    createdById: true,
    prerequisites: true,
  })
  .extend({
    slug: z.string().optional(), // used as the key
    tagIds: z.array(z.string()),
    projectTagId: z.string(),
    startDate: z.coerce.date(),
    endDate: z.coerce.date(),
    prerequisites: z.array(prerequisiteSchema).optional(),
  });

export const questRewardOverviewSchema = z.object({
  slug: z.string(),
  chain: z.string(),
  currency: z.string(),
  address: z.string().nullable(),
  agentStatus: questUserStatusSchema.nullable(),
  earnPerAddress: z.boolean(),
  rewardType: rewardTypeSchema,
  availableSlots: z.number().nullable(),
  totalSlots: z.number().nullable(),
  tokenEarnable: z.number(),
  maxTokenEarnable: z.number(),
  usdEarned: z.number(),
  tokenEarned: z.number(),
  agentHasCompleted: z.boolean(),
  totalParticipants: z.number(),
  isPrerequisiteMet: z.boolean(),
  missingPrereqs: z.array(z.object({ label: z.string(), description: z.string() })),
  buckets: z.array(bucketSchema.extend({ id: z.string() })),
  userBucketId: z.string().nullable(),
});

export type QuestRewardOverview = z.infer<typeof questRewardOverviewSchema>;
export type Step = z.infer<typeof stepSchema>;
export type InfoStep = z.infer<typeof infoStepSchema>;
export type QuestStep = z.infer<typeof questStepSchema>;
export type Quest = z.infer<typeof schema>;
export type QuestUpsert = z.infer<typeof questUpsertSchema>;
export type QuestEcosystem = z.infer<typeof questEcosystemSchema>;
