import { z } from "zod";

export const bucketSchema = z.object({
  minScore: z.number(),
  maxScore: z.number(),
  slots: z.number(),
  rewardAmount: z.number(),
  percentageAmount: z.number(),
});

export const rewardTypeSchema = z.enum(["FIXED", "VOLUME_PERCENTAGE", "FEE_PERCENTAGE"]);

export const prerequisiteSchema = z.object({ sql: z.string(), label: z.string(), description: z.string() });

export const rewardConfigSchema = z.object({
  rewardType: rewardTypeSchema,
  Buckets: z.array(bucketSchema),
  rewardChain: z.string(),
  rewardCurrency: z.string(),
  validationSql: z.string(),
  prerequisites: z.array(prerequisiteSchema),
  earnPerAddress: z.boolean(),
  ecosystem: z.string(),
  partnerId: z.string(),
});

export const rewardConfigUpsertSchema = rewardConfigSchema.omit({ ecosystem: true });

export type RewardConfig = z.infer<typeof rewardConfigSchema>;
export type RewardConfigUpsert = z.infer<typeof rewardConfigUpsertSchema>;
export type RewardType = z.infer<typeof rewardTypeSchema>;
