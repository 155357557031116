import { coerce, z } from "zod";
import { chainIdSchema } from "../rewards/rewards";

export const manualPaymentTypeSchema = z.enum([
  "FINANCE",
  "DIRECT_TO_ANALYST",
  "AMBASSADOR",
  "MODERATOR",
  "REVIEW_NETWORK",
  "EGO_BOOST",
  "TRUEUP_QUEST",
  "OTHER",
  "TRUEUP_TOPN",
  "TIP_TOPN",
  "GRAIL",
]);

// esentially just a URPR
export const claimableManualPaymentSchema = z.object({
  id: z.string().uuid(),
  userId: z.string().uuid(),
  profileId: z.union([z.string().uuid(), z.literal("default")]),
  chain: chainIdSchema,
  currency: z.string(),
  tokenEarned: z.coerce.number(),
  tokenClaimed: z.coerce.number(),
  usdEarned: z.coerce.number(),
  usdClaimed: z.coerce.number(),
  RewardSourceType: z.enum(["REWARDS_PROGRAM", "USER_QUEST_STATUS", "MANUAL"]),
  rewardSourceId: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

export const adhocManifestItem = z.object({
  name: z.string().nullable().optional(),
  type: manualPaymentTypeSchema,
  datastudioId: z.string(),
  profileId: z.string().nullable().optional(),
  chain: z.string(),
  currency: z.string(),
  tokenAmount: z.string().optional(),
  usdAmount: z.string().optional(),
  priceScrapeTime: coerce.date().optional(),
  note: z.string().nullable().optional(),
});

export const manualPaymentItemDB = z.object({
  ...adhocManifestItem.shape,
  id: z.string(),
  createdAt: coerce.date(),
  updatedAt: coerce.date(),
  userRewardsProgramRollupId: z.string().nullable().optional(),
  manualPaymentActionId: z.string().nullable().optional(),
});

const adhocPaymentBaseSchema = z.object({
  name: z.string().nullable().optional(),
  note: z.string().nullable().optional(),
  originalData: z.record(z.unknown()).nullable().optional(),
});

export const createAdhocPaymentSchema = z.object({
  ...adhocPaymentBaseSchema.shape,
  manifest: z.array(adhocManifestItem.extend({ partnerSlug: z.string().optional() })), // TODO: Make this non optional when spend is merged into eco
  approvedBy: z.string().optional(),
});

export const adhocPaymentAction = z.object({
  ...adhocPaymentBaseSchema.shape,
  createdAt: coerce.date(),
  updatedAt: coerce.date(),
  createdByDatastudioId: z.string(),
  totalUsdAmount: z.number(),
  id: z.string(),
});

const adhocPaymentActionWithManifest = z.object({
  ...adhocPaymentAction.shape,
  manifest: z.array(manualPaymentItemDB),
});

export type AdhocManifestItem = z.infer<typeof adhocManifestItem>;
export type CreateAdhocPaymentManifestAction = z.infer<typeof createAdhocPaymentSchema>;
export type AdhocPaymentAction = z.infer<typeof adhocPaymentAction>;
export type ManualPaymentManifestItemDB = z.infer<typeof manualPaymentItemDB>;
export type AdhocPaymentActionWithManifest = z.infer<typeof adhocPaymentActionWithManifest>;
